<template>
  <div>
    <b-carousel
      v-if="bannerActive && infoflashs.length > 0"
      id="carousel-banner"
      controls
      indicators
    >
      <b-carousel-slide
        v-for="flash in infoflashs"
        :key="flash.id"
      >
        <template #img>
          <b-card
            no-body
            class="overflow-hidden cardBanner"
          >
            <b-row no-gutters>
              <img
                :src="flash.piece_jointe"
                alt=""
                class="rounded-0 imgBanner"
              >
              <b-col md="8">
                <div class="cardTextBanner pt-1">
                  <h2>{{ flash.libelle }}</h2>
                </div>
                <div
                  class="cardTextBanner"
                  v-html="flash.text_infos"
                />
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-carousel-slide>

    </b-carousel>

  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BCol, BRow, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      interval: 2500,
    }
  },
  computed: {
    bannerActive() {
      return this.$store.getters['flashinfo/bannerActive']
    },
    infoflashs() {
      return this.$store.getters['flashinfo/flashinfosActive']
    },
  },
  beforeMount() {
  },
  methods: {
  },
}

</script>
<style lang='scss'>
.cardBanner {
  max-width : 100%;
  max-height: 20vh;
  min-height: 20vh;
  // max-height : 20vh;
}
.imgBanner {
object-fit: contain;
   max-width: 100%;
   max-height: 20vh;
   width: auto;
   height: auto;
}
.cardTextBanner {
  margin-left: 10px;
}

.carousel-control-prev-icon {
  left: -25px;
  position: absolute;
}

.carousel-control-next-icon {
  right: -25px;
  position: absolute;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px !important;
  width: 100px !important;
  filter: invert(7%) sepia(84%) saturate(0%) hue-rotate(250deg) brightness(60%) contrast(99%);
}

.carousel-control-next {
  display: flex !important;
  justify-content: right !important;
}

.carousel-control-prev {
  display: flex !important;
  justify-content: left !important;
}

</style>
